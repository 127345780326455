/*
Template Name: Ubold Dashboard
Author: CoderThemes
Email: coderthemes@gmail.com
File: Responsive
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .email-msg {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  body {
    overflow-x: hidden;
  }
}
@media (max-width: 767px) {
  body {
    overflow-x: hidden;
  }
  
  .mobile-sidebar {
    left: 0px;
  }
  .mobile-content {
    left: 250px;
    right: -250px;
  }
  .wrapper-page {
    width: 90%;
  }
  .wizard > .steps > ul > li {
    width: 100%;
  }
  .wizard > .content {
    padding: 0px !important;
  }
  .wizard > .content > .body {
    float: none;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0%;
  }
  .wizard.vertical > .steps {
    display: inline;
    float: none;
    width: 100%;
  }
  .wizard.vertical > .content {
    display: inline;
    float: none;
    margin: 0%;
    width: 100%;
  }
  .navbar-nav .open .dropdown-menu {
    background-color: #ffffff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    left: auto;
    position: absolute;
    right: 0;
  }
  .todo-send {
    margin-top: 10px;
    padding-left: 15px;
  }
  .chat-inputbar {
    padding-left: 15px;
  }
  .chat-send {
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .dataTables_wrapper .col-xs-6 {
    width: 100%;
    text-align: left;
  }
  .ms-container {
    width: 100%;
  }
  .email-msg {
    display: none;
  }
}
@media (max-width: 480px) {
  .side-menu {
    z-index: 10 !important;
  }
  .button-menu-mobile {
    display: block;
  }
  .search-bar {
    display: none !important;
  }
}
@media (max-width: 420px) {
  .hide-phone {
    display: none !important;
  }
}
/* Container-alt */
@media (min-width: 768px) {
  .container-alt {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container-alt {
    width: 970px;
  }
}


@media (min-width: 1200px) {

  .nav.nav-tabs > li > a .small-text
  {  
    display: none;


  }
  
  .nav.nav-tabs > li > a .big-text
  {  
    display: block;


  }


}

@media (max-width: 1200px) {

  .nav.nav-tabs > li > a
  {
    font-size: 11px;


  }


  .nav.nav-tabs > li > a .small-text
  {  
    display: block;


  }
  
  .nav.nav-tabs > li > a .big-text
  {  
    display: none;


  }

  .nav-tabs.nav-justified>li {
    display: table-cell;
    width: 1%;
  }

  #topnav .navbar-custom .container
  {
    width: 95%;
  }


}

@media (min-width: 1200px) {
  .container-alt {
    width: 1170px;
  }
}


@media (max-width: 900px) {

    .nav.nav-tabs > li > a
    {
        font-size: 11px;
        line-height: 25px;
        margin: 0!important;
        padding: 0!important;
        
    }
    
    
    .nav.nav-tabs > li > a i
    {
        clear: both;;
        width: 100%;
        display: table;
        height: 30px;
        line-height: 30px;
        
    }
    
}

@media (max-width: 419px) {
  .topbar-left {
    width: 70px !important;
  }
  .content-page {
    margin-left: 70px;
  }
  .forced .side-menu.left {
    box-shadow: 0 12px 12px rgba(0, 0, 0, 0.1);
  }
  .enlarged .side-menu.left {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) !important;
  }
}
