@font-face 
{
	font-family: 'proximanova_light';
	src: url('./fonts/proximanova_light.eot');
	src: url('./fonts/proximanova_light.eot?#iefix') format('embedded-opentype'),
		url('./fonts/proximanova_light.woff') format('woff'),
		url('./fonts/proximanova_light.ttf') format('truetype'),
		url('./fonts/proximanova_light.svg#proximanova_light') format('svg');
	font-weight: normal;
	font-style: normal;
}


@font-face {
    font-family: 'proximanova-thin';
    src: url('./fonts/proximanova-thin.eot');
    src: url('./fonts/proximanova-thin.eot?#iefix') format('embedded-opentype'),
         url('./fonts/proximanova-thin.woff') format('woff'),
         url('./fonts/proximanova-thin.ttf') format('truetype'),
         url('./fonts/proximanova-thin.svg#proximanova-thin') format('svg');
    font-weight: normal;
    font-style: normal;
    }


@font-face {
	font-family: 'ProximaNova-Reg';
	src: url('./fonts/ProximaNova-Reg.eot');
	src: local('☺'), url('./fonts/ProximaNova-Reg.woff') format('woff'), 
         url('./fonts/ProximaNova-Reg.ttf') format('truetype'), 
         url('./fonts/ProximaNova-Reg.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}



/*ProximaNova*/

@font-face {
    font-family: 'proxima_nova_blblack';
    src: url('./fonts/proxima/ProximaNova-Black-appj.eot');
    src: url('./fonts/proxima/ProximaNova-Black-appj.eot?#iefix') format('embedded-opentype'),
         url('./fonts/proxima/ProximaNova-Black-appj.woff2') format('woff2'),
         url('./fonts/proxima/ProximaNova-Black-appj.woff') format('woff'),
         url('./fonts/proxima/ProximaNova-Black-appj.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'proxima_nova_rgbold';
    src: url('./fonts/proxima/ProximaNova-Bold-appj.eot');
    src: url('./fonts/proxima/ProximaNova-Bold-appj.eot?#iefix') format('embedded-opentype'),
         url('./fonts/proxima/ProximaNova-Bold-appj.woff2') format('woff2'),
         url('./fonts/proxima/ProximaNova-Bold-appj.woff') format('woff'),
         url('./fonts/proxima/ProximaNova-Bold-appj.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'proxima_nova_ltsemibold';
    src: url('./fonts/proxima/ProximaNova-Sbold-appj.eot');
    src: url('./fonts/proxima/ProximaNova-Sbold-appj.eot?#iefix') format('embedded-opentype'),
         url('./fonts/proxima/ProximaNova-Sbold-appj.woff2') format('woff2'),
         url('./fonts/proxima/ProximaNova-Sbold-appj.woff') format('woff'),
         url('./fonts/proxima/ProximaNova-Sbold-appj.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




/** Generated by FG **/
@font-face {
	font-family: 'DidotLTStd-Roman';
	src: url('./fonts/DidotLTStd-Roman.eot');
	src: local('☺'), url('./fonts/DidotLTStd-Roman.woff') format('woff'), url('./fonts/DidotLTStd-Roman.ttf') format('truetype'), url('./fonts/DidotLTStd-Roman.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'DidotLTStdItalic';
    src: url('./fonts/d_didot-lt-std-italic.eot');
    src: url('./fonts/d_didot-lt-std-italic.eot?#iefix') format('embedded-opentype'),
         url('./fonts/d_didot-lt-std-italic.woff') format('woff'),
         url('./fonts/d_didot-lt-std-italic.ttf') format('truetype'),
         url('./fonts/d_didot-lt-std-italic.svg#d_didot-lt-std-italic') format('svg');
    font-weight: normal;
    font-style: normal;
    }
